import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import SEO from '@atoms/seo'
import Text from '@particles/text'
import Heading from '@particles/heading'
import SearchBar from '@organisms/search-bar'
import Link from '@atoms/link'
import HomepageHero from '@organisms/homepage-hero'
import styled from 'styled-components'
import Block from '@atoms/block'
import Icon from '@icons'
import { media } from '@breakpoints'
import { truncate } from '@utils/truncate'
import { graphql } from 'gatsby'
import { formatTopPicks } from '@utils/format-top-picks'

const BackgroundContainer = styled.div`
  background: linear-gradient(180deg, #181c26 0%, #3b455e 68%, #f6f8fe 68%);

  ${media.desktop`
    background: linear-gradient(180deg, #181c26 0%, #3b455e 100%);
  `}
`

const EmbedContainer = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
  height: 800px;
  margin-top: 2rem;
  background: #CCCCCC;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
`

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 4rem;

  ${media.tablet`
    flex-flow: column;
    gap: 1rem;
  `}
`

const IndexPage = ({ data, location }) => {
  const ranked = formatTopPicks(data)
  const settings = data.allHomepageSettings.nodes[0]

  return (
    <Layout fullWidth disableSearch={true} location={location} disableForm>
      <SEO
        title="MetricHQ - Dictionary of metrics and KPIs"
        override
        location={location}
      />
      <BackgroundContainer>
        <Layout.Container>
          <Heading
            h1
            center
            color="white"
            style={{
              fontSize: 'clamp(2.2rem, calc(0.20rem + 4vw), 4.2rem)',
              fontWeight: '700'
            }}
            margin="T6 B3"
          >
            Your place for
            <br />
            <Text as={'span'} bold color="#75E1B2" size="inherit">
              everything
            </Text>
            &nbsp;metrics
          </Heading>

          <SearchBar margin="T3" />
          <Link
            to="/chatbot"
            style={{
              fontWeight: 600,
              display: 'flex',
              marginTop: '1rem',
              gap: '0.5rem',
              color: '#75E1B2',
              alignItems: 'center'
            }}
          >
            Learn about metrics with our AI metric assistant&nbsp;
            <Icon size={0.8} color={'white'} name={'arrow-green'} />
          </Link>

          <HomepageHero ranked={ranked.topPicks} />
        </Layout.Container>
      </BackgroundContainer>
      
      <Layout.Container>
        <HeaderWrapper>
          <Heading h2>Popular Metrics</Heading>
          <Link to="/all-metrics">See all metrics</Link>
        </HeaderWrapper>
        <Layout.Grid margin="T2 B2" full min={300} gap={1.5}>
          {ranked.metrics.slice(0, 3).map((metric, i) => {
            return (
              <Block key={`popular-metric-${i}`} to={metric.slug}>
                <Text size={1.4} margin="B1">
                  {metric.name}
                </Text>
                <Text size={1} margin="B1">
                  {truncate(metric.description, 120)}
                </Text>
                <Text color="#34AD78" bold size={1}>
                  Learn more
                </Text>
              </Block>
            )
          })}
        </Layout.Grid>
      </Layout.Container>
      <Layout.Container>
        <HeaderWrapper>
          <Heading h2>Popular Categories</Heading>
          <Link to="/all-metrics">See all categories</Link>
        </HeaderWrapper>
        <Layout.Grid margin="T2 B2" full min={150} gap={1.5}>
          {settings.categories?.slice(0, 5)?.map((category, i) => {
            return (
              <Block key={`popular-category-${i}`} to={category.slug}>
                <Text size={0.9}>
                  {category.metrics.length} Metric
                  {category.metrics.length > 1 && 's'}
                </Text>
                <Text bold lineHeight={1.2} size={1.3}>
                  {category.name} Metrics
                </Text>
              </Block>
            )
          })}
        </Layout.Grid>
        <HeaderWrapper>
          <Heading h2>Popular Services</Heading>
          <Link to="/all-services">See all services</Link>
        </HeaderWrapper>
        <Layout.Grid margin="T2 B2" full min={150} gap={1.5}>
          {ranked.services.slice(0, 5).map((service, i) => {
            return (
              <Block.Icon
                key={`popular-service=${i}`}
                type="Service"
                data={service}
                to={service.slug}
              />
            )
          })}
        </Layout.Grid>
        <HeaderWrapper>
          <Heading h2>Popular Contributors</Heading>
          <Link to="/contributors">See all contributors</Link>
        </HeaderWrapper>
        <Layout.Grid margin="T2 B2" full min={150} gap={1.5}>
          {ranked.contributors.slice(0, 5).map((contributor, i) => {
            return (
              <Block.Icon
                key={`popular-contributor=${i}`}
                type="Contributor"
                data={contributor}
                to={contributor.slug}
              />
            )
          })}
        </Layout.Grid>
      </Layout.Container>
      <Layout.Container>
        <HeaderWrapper>
          <Heading h2 id="live-embed">Explore a Sample Metric</Heading>
        </HeaderWrapper>
        <Text style={{alignSelf: "start"}} margin={"T0.5"}>
          Interact with sample metric data in real time with a live embed from <Link target="_blank" href="https://www.klipfolio.com/powermetrics">Klipfolio PowerMetrics.</Link>
        </Text>
        <EmbedContainer>
          <embed-client token="https://www.metrichq.org/api/sign" id="demo" metrics="c9b0ad62c0a79d4c6a3efd792c7aed3e" theme="light" readonly="false" showpropspanel="true">
            ewoJInZpcyI6IHsKCQkiY29sb3VyaW5nU3RyYXRlZ3kiOiAiaGFzaGluZyIsCgkJInR5cGUiOiAic21vb3RoX2xpbmUiCgl9LAoJImRhdGEiOiB7CgkJInNsb3RzIjogewoJCQkiY2F0ZWdvcmllcyI6IFsKCQkJCXsKCQkJCQkibmFtZSI6ICJ0aW1lIgoJCQkJfQoJCQldLAoJCQkiZGltZW5zaW9uIjogW10KCQl9LAoJCSJhbmFseXNlcyI6IHsKCQkJIlNQQyI6IHRydWUKCQl9Cgl9LAoJInZlcnNpb24iOiAzCn0=
          </embed-client>
        </EmbedContainer>
      </Layout.Container>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    allHomepageBlocks {
      edges {
        node {
          name
          description
          link
          type
          image {
            cdn
          }
          slot
        }
      }
    }
    allServices(filter: { status: { eq: "published" } }) {
      edges {
        node {
          slug
          name
          instantMetricsCount
          metrics {
            id
            date_updated
            date_created
          }
        }
      }
    }
    allMetric(filter: { status: { eq: "published" } }) {
      nodes {
        name
        slug
        description
        date_updated
        date_created
      }
    }
    allContributor(
      filter: {
        status: { eq: "published" }
        metrics: { elemMatch: { status: { eq: "published" } } }
      }
    ) {
      edges {
        node {
          date_created
          name
          metrics {
            id
            date_updated
            date_created
          }
          image {
            cdn
          }
          subtitle
        }
      }
    }
    allHomepageSettings {
      nodes {
        categories {
          id
          name
          description
          slug
          metrics {
            id
          }
        }
      }
    }
    settings {
      popular_metrics {
        name
        slug
      }
      popular_services {
        name
        logo {
          cdn
        }
        instantMetricsCount
        slug
      }
    }
  }
`
