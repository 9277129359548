import React from 'react'
import Card from '@atoms/card'
import styled, { css } from 'styled-components'
import { media } from '@breakpoints'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import ServiceLogo from '@atoms/service-logo'
import Image from '@atoms/image'
import { BLOCK_TYPES } from './constants'

export const StyledCard = styled(Card)`
  padding: 2rem;
  justify-content: space-between;
  text-decoration: none;

  ${media.desktop`
    grid-column: ${(props) => !props.featured && 'span 1'};
    grid-row: ${(props) => !props.featured && 'span 1'};
  `}

  ${media.phablet`
    display: ${(props) => !props.featured && props.grid && 'none'};
  `}

  background: ${(props) => !props.featured && props.highlighted && '#F5FFF2'};

  ${(props) =>
    props.featured &&
    css`
      grid-row: span 2;
      grid-column: span 2;
      background-color: #00805d;
      background: ${(props) =>
        props.image?.cdn &&
        `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("${props.image.cdn}"), #00805D`};
      background-size: cover;
    `}

  ${(props) =>
    props.wide &&
    css`
      grid-column: span 2;
    `}
`

const ContributorIcon = styled.div`
  min-width: 60px;
  min-height: 60px;
  height: 60px;
  width: 60px;
  img {
    width: 100%;
    height: 100%;
  }
  border-radius: 50%;
  background-color: #d7dcea;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const Block = ({
  featured,
  wide,
  highlighted,
  children,
  image,
  ...rest
}) => {
  return (
    <StyledCard
      as={GatsbyLink}
      auto
      featured={featured}
      wide={wide}
      image={image}
      highlighted={highlighted}
      {...rest}
    >
      {children}
    </StyledCard>
  )
}

Block.propTypes = {
  featured: PropTypes.bool,
  wide: PropTypes.bool,
  highlighted: PropTypes.bool,
  image: PropTypes.object,
  children: PropTypes.node
}

export const ImageIcon = ({ data }) => {
  return data.type === BLOCK_TYPES.CONTRIBUTOR ? (
    <ContributorIcon>
      <Image file={data.image} alt={`${data.name}'s profile picture`} />
    </ContributorIcon>
  ) : (
    <ServiceLogo
      style={{ background: '#F6F7FE', alignSelf: 'start' }}
      sm
      logo={data.name}
    />
  )
}

ImageIcon.propTypes = {
  data: PropTypes.object
}
