import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Heading from '@particles/heading'
import Text from '@particles/text'
import { truncate } from '@utils/truncate'
import { Block, ImageIcon } from './styles'
import { BLOCK_TYPES } from './constants'

Block.Text = ({ featured, wide, data, ...rest }) => {
  return (
    <Block featured={featured} wide={wide} image={data.image} {...rest}>
      <Text size={featured ? 2 : 1.4} color={featured && 'white'} margin="B1">
        {wide ? data.name : data.type}
      </Text>
      {featured && !wide && (
        <Text
          bold
          color="white"
          lineHeight={1.2}
          size={2.5}
          style={{
            maxWidth: '10ch'
          }}
        >
          {data.name}
        </Text>
      )}
      {!featured && wide && (
        <div>
          {data.description && (
            <Text size={1} margin="B1">
              {truncate(data.description, 120)}
            </Text>
          )}
          <Text color="#34AD78" bold size={1}>
            Learn more
          </Text>
        </div>
      )}
      {!featured && !wide && (
        <Text bold lineHeight={1.2} size={1.7}>
          {data.name}
        </Text>
      )}
    </Block>
  )
}

Block.Text.propTypes = {
  featured: PropTypes.bool,
  wide: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.object
  })
}

Block.Text.displayName = 'Text Block'

const ServiceInfo = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

Block.Icon = ({ featured, wide, data, ...rest }) => {
  return (
    <Block featured={featured} wide={wide} {...rest}>
      {featured || wide ? (
        <Text size={featured ? 2 : 1.4} color={featured && 'white'} margin="B1">
          {data.type}
        </Text>
      ) : (
        <ImageIcon data={data} />
      )}
      {featured && (
        <div>
          <Heading
            h1
            lineHeight={1.2}
            color="white"
            style={{
              maxWidth: '10ch'
            }}
            medium
          >
            {data.name}
          </Heading>
          <Text size={0.9} color="white">
            {data.count} Metrics
          </Text>
        </div>
      )}
      {!featured && (
        <ServiceInfo>
          {(featured || wide) && <ImageIcon data={data} />}
          <div>
            <Text bold lineHeight={1.2} size={1.2}>
              {data.name}
            </Text>
            <Text size={0.9}>{data.count} Metrics</Text>
          </div>
        </ServiceInfo>
      )}
    </Block>
  )
}

Block.Icon.propTypes = {
  featured: PropTypes.bool,
  wide: PropTypes.bool,
  data: PropTypes.object,
  service: PropTypes.shape({
    name: PropTypes.string,
    metrics: PropTypes.array,
    count: PropTypes.number
  })
}

Block.Icon.displayName = 'Icon Block'

const getBlockFromType = (type) => {
  switch (type) {
    case BLOCK_TYPES.METRIC:
      return Block.Text
    case BLOCK_TYPES.SERVICE:
      return Block.Icon
    case BLOCK_TYPES.CONTRIBUTOR:
      return Block.Icon
    default:
      return Block.Text
  }
}

Block.Auto = ({ content, ...rest }) => {
  const BlockType = useMemo(() => getBlockFromType(content.type))
  return (
    <BlockType
      to={content.slug}
      data={content}
      highlighted={content.highlighted}
      {...rest}
    />
  )
}

Block.Auto.displayName = 'Auto Block'

Block.Auto.propTypes = {
  content: PropTypes.shape({
    slug: PropTypes.string,
    type: PropTypes.string,
    highlighted: PropTypes.bool
  })
}

export default Block
