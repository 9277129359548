import React from 'react'
import PropTypes from 'prop-types'
import Block from '@atoms/block'
import Layout from '@organisms/layout'

const HomepageHero = ({ ranked }) => {
  return (
    <Layout.Grid
      margin="T6 B2"
      gridTemplate="repeat(auto-fit, minmax(230px, 1fr))"
      gap={1.5}
    >
      <Block.Auto content={ranked[0]} featured grid />
      <Block.Auto content={ranked[1]} wide grid />
      <Block.Auto content={ranked[2]} grid />
      <Block.Auto content={ranked[3]} grid />
      <Block.Auto content={ranked[4]} wide grid />
      <Block.Auto content={ranked[5]} wide grid />
      <Block.Auto content={ranked[6]} grid />
      <Block.Auto content={ranked[7]} grid />
      <Block.Auto content={ranked[8]} grid />
    </Layout.Grid>
  )
}

HomepageHero.propTypes = {
  ranked: PropTypes.array
}

export default HomepageHero
