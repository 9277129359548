const { BLOCK_TYPES } = require("../atoms/block/constants")

function isRecent(date) {
  const compare = new Date(date)
  const today = new Date()

  const lastMonth = new Date()
  lastMonth.setMonth(lastMonth.getMonth() - 3)

  return compare >= lastMonth && compare <= today
}

function formatBlock(object) {
  const block = object.node

  return {
    name: block.name,
    description: block.description,
    slug: block.link,
    image: block.image,
    highlighted: true,
    slot: block.slot,
    type: block.type
  }
}

function formatContributor(object) {
  const contributor = object.node
  const score = scoreWithMetrics(contributor)
  const highlight = isRecent(contributor.date_created) || isRecent(contributor.date_updated)

  return {
    name: contributor.name,
    slug: `/contributor/${contributor.name.toLowerCase().replace(/ /g, '_')}`,
    image: contributor.image,
    highlighted: highlight,
    score: score,
    count: contributor.metrics.length,
    type: BLOCK_TYPES.CONTRIBUTOR
  }
}

function formatService(object) {
  const service = object.node
  const score = scoreWithMetrics(service)
  const highlight = isRecent(service.date_created) || isRecent(service.date_updated)

  return {
    name: service.name,
    slug: service.slug,
    highlighted: highlight,
    score: score,
    count: service.instantMetricsCount,
    type: BLOCK_TYPES.SERVICE
  }
}

function scoreWithMetrics(data) {
  let score = 0

  const newMetrics = []
  const updatedMetrics = []
  data.metrics?.map((metric) => {
    if (isRecent(metric.date_created)) {
      newMetrics.push(metric)
    } else if (isRecent(metric.date_updated)) {
      updatedMetrics.push(metric)
    }
  })

  if (newMetrics.length > 0) {
    score += 5
  }

  if (updatedMetrics.length > 0) {
    score += 3
  }

  if (isRecent(data.date_created)) {
    score += 3
  }

  if (isRecent(data.date_updated)) {
    score += 1
  }

  if (data.metrics.length > 0) {
    score += 1
  }

  return score
}

function formatMetric(metric) {
  let score = 0
  let isNew = false
  let isUpdated = false

  if (isRecent(metric.date_created)) {
    score += 5
    isNew = true
  }

  if (isRecent(metric.date_updated)) {
    score += 3
    isUpdated = true
  }

  return {
    name: metric.name,
    description: metric.description,
    slug: metric.slug,
    highlighted: isUpdated || isNew,
    score: score,
    type: BLOCK_TYPES.METRIC
  }
}

function formatPicks(format, picks, sort) {
  return picks.map((pick) => format(pick)).sort(sort)
}

function sortByScore(a, b) {
  return b.score - a.score
}

function sortBySlot(a, b) {
  return a.slot - b.slot
}

function formatTopPicks(data) {
  const contributors = formatPicks(formatContributor, data.allContributor.edges, sortByScore)
  const services = formatPicks(formatService, data.allServices.edges, sortByScore)
  const metrics = formatPicks(formatMetric, data.allMetric.nodes, sortByScore)
  const blocks = formatPicks(formatBlock, data.allHomepageBlocks.edges, sortBySlot)

  const topPicks = []
  for (let i = 0; i <= 8; i++) {
    if (blocks.length) {
      if (blocks[0].slot == i) {
        topPicks.push(blocks.shift())
        continue
      }
    } else if (i === 0) {
      if (services[0].score > metrics[0].score) {
        topPicks.push(services.shift())
      } else {
        topPicks.push(metrics.shift())
      }
    }

    if (i % 3 === 1) {
      topPicks.push(contributors.shift())
    } else if (i % 3 === 2) {
      topPicks.push(services.shift())
    } else if (i % 3 === 0) {
      topPicks.push(metrics.shift())
    }
  }

  return ({ contributors, services, metrics, topPicks })
}

module.exports = { formatTopPicks }
